@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: @primary-color;
}

button {
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
  &.link {
    color: @primary-color;
    -webkit-appearance: none;
    &:focus,
    &:active {
      color: darken(@primary-color, 10%);
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  display: initial;
}

svg {
  vertical-align: unset;
}

@media print {
  .ant-layout-content {
    height: auto !important;
  }
  .ant-page-header {
    padding: 0 !important;
  }
  .ant-page-header-heading {
    display: none !important;
  }
  .ant-page-header-content {
    padding: 0 !important;
  }

  * {
    visibility: hidden;
  }
  .print,
  .print * {
    visibility: visible !important;
  }

  .no-print {
    display: none !important;
  }

  .print {
    display: block !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .A4 {
    display: block !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;

    page-break-after: always;
    &:last-child {
      page-break-after: auto;
    }
  }

  .recharts-tooltip-wrapper {
    display: none;
  }
}

.text-primary {
  color: @primary-color;
}
.bg-primary {
  background-color: @primary-color;
}
.border-primary {
  border-color: @primary-color;
}

// patch antd
.ant-statistic-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-title {
  font-size: 12px;
}
.ant-layout-sider {
  .ant-menu-submenu-title,
  .ant-menu-root > .ant-menu-item {
    margin-left: 8px !important;
    margin-right: 8px !important;
    padding-left: 16px !important;
    width: auto !important;
    height: 56px !important;
    line-height: 56px !important;
    margin-bottom: 0 !important;
    border-radius: 8px !important;
  }
  .ant-menu-sub .ant-menu-item {
    height: 48px !important;
    line-height: 48px !important;
    margin-bottom: 0 !important;
    &::after {
      border-right: 0 !important;
    }
  }
  .ant-menu-root > .ant-menu-item-selected {
    background-color: @primary-color !important;
    a {
      color: white !important;
    }
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: @primary-color !important;
    color: white !important;
    .ant-menu-submenu-arrow {
      &::before,
      &::after {
        background-image: none !important;
        background-color: white !important;
      }
    }
  }
}

.-mx-6 > .ant-table-wrapper {
  th:first-child,
  td:first-child {
    padding-left: 24px;
  }

  th:last-child,
  td:last-child {
    padding-right: 24px;
  }
}

@primary-color: #397788;